<template>
  <div class="main">
    <div class="activity-list">
      <div class="title">
        <span class="date">开展日期</span>
        <span class="type">活动类型</span>
        <span class="theme">课程主题</span>
        <span class="num">参加人数</span>
      </div>

      <vue-seamless-scroll v-if="listData.length" :data="listData" class="warp">
        <div style="display: flex" v-for="item in listData" class="warp-item">
          <span class="date"
            >{{ moment(item.createTime).format('YYYY/MM/DD') }}-{{
              moment(item.endTime).format('YYYY/MM/DD')
            }}</span
          >
          <span class="type">{{ item.category }}</span>
          <span class="theme">{{ item.topic }}</span>
          <span class="num">{{
            (item.num + item.externalNum + item.staffNum).toFixed(0)
          }}</span>
        </div>
      </vue-seamless-scroll>
      <div v-if="!listData.length" class="noMessage">暂无数据</div>
    </div>
  </div>
</template>

<script>
import { getActivity } from '@/api/serviceComplex'
export default {
  data() {
    return {
      listData: [],
    }
  },
  computed: {
    optionLefts() {
      return {
        //控制滚动方向
        direction: 1,
        //控制多少条数开始滚动
        limitMoveNum: 3,
        //控制滚动速度，数值越大速度滚动越快
        step: 0.1,
      }
    },
  },
  mounted() {
    this.getActivity()
  },
  methods: {
    getActivity() {
      const { insNo } = this.$route.query
      getActivity(insNo).then((res) => {
        console.log(res.data.success)
        if (res.data.success) {
          this.listData = res.data.data || []
        }
      })
    },
  },
}
</script>

<style scoped>
.main {
  width: 100%;
  height: 9.9rem;
  background: url('../../../../image/twoThreeImgs/left/activityBg.png') 0 0
    no-repeat;
  background-size: 100% 100%;
  margin-top: 0.41rem;
  overflow: hidden;
}
.activity-list {
  margin: 0rem 0.41rem 0.5rem 0.41rem;
  width: calc(100% - 0.82rem);
  height: 7.58rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 0.08rem;
  border: 0.04rem solid #213060;
  margin-top: 1.5rem;
  z-index: 99;
}
.title {
  display: flex;
  font-size: 0.5rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  line-height: 0.5rem;
  border-radius: 0.08rem;
  border-bottom: 0.04rem solid #213060;
  padding: 0.33rem 0.5rem;
  z-index: 99;
}
.date {
  flex: 3;
}

.type {
  flex: 2;
  padding: 0 0.5rem;
}
.theme {
  flex: 2.5;
  padding: 0 0.5rem;
}
.num {
  flex: 1;
}
.warp {
  z-index: 99;
  overflow: hidden;
  margin: 0 0.5rem;
  height: calc(100% - 1.25rem);
}
.warp .warp-item {
  height: 1.7rem;
  display: flex;
  align-items: center;
}
.warp .date,
.warp .type,
.warp .theme {
  font-size: 0.58rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #b4d3fe;

  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.warp .num {
  font-size: 0.58rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #b4d3fe;
}
.noMessage {
  font-size: 1.2rem;
  color: #2d3769;
  text-align: center;
  margin-top: 2.5rem;
}
</style>
